import { TIMEOUT_SEC } from "./config.js";

//Promisify setTimeout function (of 's' seconds wait) and assign to 'timeout' variable
const timeout = function (s) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${s} second`));
    }, s * 1000);
  });
};

//API Fetch for various url/methods/body data, including token fetch, and with timeout of 's' seconds
export const AJAX = async function (
  url,
  token = undefined,
  uploadData = undefined,
  fetchMethod
) {
  try {
    const fetchPro = uploadData
      ? fetch(url, {
          method: fetchMethod,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(uploadData),
        })
      : fetch(url, {
          method: fetchMethod,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

    const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);
    // Do not parse json for status=204 as there is no content returned in response body for Delete action
    const data = res.status !== 204 ? await res.json() : await res.text();
    //will return a status and uploaded data back
    if (!res.ok) throw new Error(`${data.message} (${res.status})`);
    return data;
  } catch (err) {
    throw err; //to throw the err to be handled within model.js instead of here
  }
};
