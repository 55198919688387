import {
  API_JWT_ACCESS_URL,
  API_JWT_VERIFY_URL,
  API_EVENT_URL,
  API_USER_URL,
} from "./config.js";
import { AJAX } from "./helpers.js";

//Initialize the required object data and group them under state object

export const state = {
  event: {
    results: [],
    resultscopy: [],
    resultscopychange: [],
    resultscombine: [],
    resultsdaily: [],
  },
};

// To retrieve the JWT access and refresh token and save it in Local Storage
const loadToken = async function (loginDetails) {
  try {
    const method = "POST";
    const data = await AJAX(
      `${API_JWT_ACCESS_URL}`,
      null,
      loginDetails,
      method
    );
    localStorage.setItem("accesstoken", JSON.stringify(data.access));
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To verify token validity
const verifyToken = async function (token, tokenJson) {
  try {
    const method = "POST";
    const data = await AJAX(`${API_JWT_VERIFY_URL}`, token, tokenJson, method);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//Manage login with ID and password
export const login = async function (newLogin) {
  try {
    //convert to API package format
    const loginDetails = {
      email: newLogin.loginid,
      password: newLogin.loginpassword,
    };
    //GET access token
    await loadToken(loginDetails);
    console.log(loginDetails);
    const token = localStorage.getItem("accesstoken");
    if (token !== null) {
      window.location.href = "./main.html";
      localStorage.setItem("username", JSON.stringify(newLogin.loginid));
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//Check login status
export const isLoggedin = async function () {
  try {
    const token = localStorage.getItem("accesstoken");
    if (token === null) {
      window.location.href = "./index.html";
    }
    if (token !== null) {
      const tokenJson = JSON.parse(`{"token":${token}}`);
      await verifyToken(token, tokenJson);
    }
  } catch (err) {
    console.error(err);
    logout(); //redirect to login page and remove the expired access token
  }
};

//Log out user
export const logout = async function () {
  try {
    // Remove the token and redirect to login page
    localStorage.removeItem("accesstoken");
    localStorage.removeItem("username");
    window.location.href = "./index.html";
  } catch (err) {
    console.error(err);
    throw err;
  }
};

// To load the Event search results
export const loadEventResults = async function (query) {
  try {
    const searchQuery = query === null ? "#" : query;
    // console.log(searchQuery);
    //Retrieve the token from the local storage and remove the double quote.
    const token = localStorage.getItem("accesstoken").slice(1, -1);

    //GET event API using customized Django filterset (DjangoFilterBackend) to find all events for this year and next/last year (limit to 3 years) and access token
    // Only DjangoFilterBackend can use ?field_name= in query.
    // const data = await AJAX(`${API_EVENT_URL}?search=${searchQuery}`, token);
    const data = await AJAX(
      `${API_EVENT_URL}?from_start_date=${
        searchQuery - 1
      }-01-01&to_start_date=${searchQuery + 1}-12-31`,
      token
    );

    // console.log(data);
    state.event.resultscopy = [];
    state.event.resultscopychange = [];
    state.event.resultscombine = [];
    state.event.resultsdaily = [];
    state.event.results = data.map((event) => {
      //logic to extract those events that spread across > 1 day
      if (event.end_date > event.start_date) {
        const start = new Date(event.start_date);
        const end = new Date(event.end_date);
        let loop = new Date(start);
        // i must start from the right index which is where the next multi-day event will be pushed in, and this is equal to current length of the array
        let i = state.event.resultscopy.length;
        const init_length = state.event.resultscopy.length;
        // Duplicate n times based on # of days spread, push to another array and change the end date of each event based on + 1 day increment from start date till end date. Also change the start and end time to reflect full day except for the 1st day's start time to remain
        while (loop < end) {
          //spread operators is required to remove the reference of the copied object in resultscopy array to the same object in results array. With the reference, a change in one's value will result the change in all copied objects.
          state.event.resultscopy.push({ ...event });
          let loopdate = loop.toLocaleDateString("sv-SE".substring(0, 10));
          state.event.resultscopy[i].end_date = loopdate;
          state.event.resultscopy[i].start_date =
            state.event.resultscopy[i].end_date;
          // Time counts from 00:00 to 24:00, thus it cannot display if its end time is 00:00. Starttime and Endtime at 00:00 is used to determine a full day event.
          state.event.resultscopy[i].end_time =
            i === init_length ? "24:00:00" : "00:00:00";
          if (i !== init_length) {
            state.event.resultscopy[i].start_time = "00:00:00";
          }
          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
          i++;
        }
        // console.log(state.event.resultscopy);
      }

      // To remove the preceding zeros in month and day so that comparison can be done in monthView against the related month & day
      const newstartdate = event.start_date.replace(/-0+/g, "-");
      const newenddate = event.end_date.replace(/-0+/g, "-");
      const newstarttime = event.start_time.slice(0, -3);
      const newendtime = event.end_time.slice(0, -3);

      return {
        eventid: event.event_id,
        eventtitle: event.event_title,
        origstartdate: event.start_date,
        origstarttime: event.start_time,
        origenddate: event.end_date,
        origendtime: event.end_time,
        startdate: newstartdate,
        starttime: newstarttime,
        enddate: newenddate,
        endtime: newendtime,
        host: event.host,
        invitees: event.invitees,
        multiday: 0,
      };
    });
    // console.log(state.event.results);
    state.event.resultscopychange = state.event.resultscopy.map((event) => {
      //To remove the preceding zeros in month and day so that comparison can be done in monthView against the related month & day

      const newstartdate = event.start_date.replace(/-0+/g, "-");
      const newenddate = event.end_date.replace(/-0+/g, "-");
      const newstarttime = event.start_time.slice(0, -3);
      const newendtime = event.end_time.slice(0, -3);
      return {
        eventid: event.event_id,
        eventtitle: event.event_title,
        origstartdate: event.start_date,
        origstarttime: event.start_time,
        origenddate: event.end_date,
        origendtime: event.end_time,
        startdate: newstartdate,
        starttime: newstarttime,
        enddate: newenddate,
        endtime: newendtime,
        host: event.host,
        invitees: event.invitees,
        multiday: event.event_id,
      };
    });

    // JSON.stringify turns an object into a string. JSON.parse turns a string into an object. Combining them can turn an object into a string, and then reverse the process to create a brand new data structure. This one safely copies deeply nested objects/arrays and totally remove the reference of the copied array (resultscombine) to the original array (results). If not any change in resultscombine will result in change in results.
    state.event.resultscombine = JSON.parse(
      JSON.stringify(state.event.results)
    ).concat(state.event.resultscopychange);

    state.event.resultsdaily = state.event.resultscombine.map((event) => {
      if (event.startdate !== event.enddate) {
        event.starttime = "00:00"; // changing the last day of multi-day event start time to be 00:00
        event.startdate = event.enddate;
        event.multiday = event.eventid;
      }
      return event;
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To add a new event entered via UI form

export const addNewEvent = async function (newEvent) {
  try {
    //convert to API package format
    const eventCreated = {
      event_title: newEvent.eventtitle,
      start_date: newEvent.startdate,
      start_time: newEvent.starttime,
      end_date: newEvent.enddate,
      end_time: newEvent.endtime,
      host: newEvent.host,
      invitees: newEvent.invitees,
    };
    // console.log(eventCreated);
    //Upload new event by calling sendJSON to post it via API call
    const token = localStorage.getItem("accesstoken").slice(1, -1);
    const method = "POST";
    const uploaddata = await AJAX(
      `${API_EVENT_URL}`,
      token,
      eventCreated,
      method
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To edit the Event

export const editEvent = async function (editEvent) {
  try {
    //convert to API package format
    const eventEdited = {
      event_id: editEvent.eventid,
      event_title: editEvent.editeventtitle,
      start_date: editEvent.editstartdate,
      start_time: editEvent.editstarttime,
      end_date: editEvent.editenddate,
      end_time: editEvent.editendtime,
      host: editEvent.edithost,
      invitees: editEvent.editinvitees,
    };
    // console.log(eventEdited);
    //Upload edited event by calling sendJSON to post it via API call
    const token = localStorage.getItem("accesstoken").slice(1, -1);
    const method = "PUT";
    const uploaddata = await AJAX(
      `${API_EVENT_URL}${eventEdited.event_id}/`,
      token,
      eventEdited,
      method
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

//To delete the Event

export const deleteEvent = async function (deleteEvent) {
  try {
    // Delete event by calling sendJSON to post it via API call
    const token = localStorage.getItem("accesstoken").slice(1, -1);
    const method = "DELETE";
    const uploaddata = await AJAX(
      `${API_EVENT_URL}${deleteEvent}/`,
      token,
      null,
      method
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};
